<template>
  <ServicesDescription
    @connectService="editDetails"
    :buttonText="buttonText"
    :buttonIcon="buttonIcon"
  >
    <template #title>
      <span> Мои реквизиты </span>
    </template>
    <template #body>
      <span v-for="(item, index) in customerData" :key="index">
        {{ item.name }} {{ item.value }}
        <br />
      </span>
    </template>
  </ServicesDescription>
</template>

<script>
import ServicesDescription from '@/components/shared/ServicesDescription.vue';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      buttonText: 'Изменить реквизиты',
      buttonIcon: 'pencil-fill'
    };
  },

  components: {
    ServicesDescription
  },

  computed: mapGetters({
    customerData: 'statements/customerData'
  }),

  methods: {
    editDetails() {
      this.$router.push({ path: '/create-statement', query: { st: 'orgDetails' } });
    }
  }
};
</script>

<style></style>
