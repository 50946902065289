<template>
  <the-wrapper :isSpinner="isSpinner" :class="$style.rowData">
    <org-details-company></org-details-company>
    <br />
    <br />
    <org-details-customer></org-details-customer>
  </the-wrapper>
</template>

<script>
import OrgDetailsCompany from '@/components/org_details/OrgDetailsCompany.vue';
import OrgDetailsCustomer from '@/components/org_details/OrgDetailsCustomer.vue';
import TheWrapper from '@/components/shared/TheWrapper.vue';
import { mapActions, mapMutations } from 'vuex';

export default {
  data() {
    return {
      isSpinner: true
    };
  },

  components: {
    OrgDetailsCompany,
    OrgDetailsCustomer,
    TheWrapper
  },

  created() {
    this.getCompanyAndCustomerData({ vm: this });
  },

  methods: {
    ...mapActions({ getCompanyAndCustomerData: 'statements/getCompanyAndCustomerData' }),

    ...mapMutations({
      SHOW_LOADING: 'SHOW_LOADING'
    })
  }
};
</script>

<style module>
.rowData {
  line-height: 1.875;
}
</style>
