<template>
  <ServicesDescription :isFooter="isFooter">
    <template #title>
      <span>Реквизиты</span>
      <br />
      <br />
      <span>{{ companyData.name.ShortName }}</span>
    </template>
    <template #body>
      <span> Полное наименование: {{ companyData.name.FullName }} </span>
      <p>Сокращенное наименование: {{ companyData.name.ShortName }}</p>
      <span v-for="(item, index) in companyData.details" :key="index">
        {{ item.name }} {{ item.value }}
        <br />
      </span>
    </template>
  </ServicesDescription>
</template>

<script>
import ServicesDescription from '@/components/shared/ServicesDescription.vue';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isFooter: false
    };
  },

  components: {
    ServicesDescription
  },

  computed: mapGetters({ companyData: 'statements/companyData' })
};
</script>

<style module></style>
